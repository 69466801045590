import React, { useState, useEffect } from "react"
import { graphql, Link, navigate } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Anlam from 'anlam';

export default function Template({
  data, path // this prop will be injected by the GraphQL query below.
}) {

  let pathname = "/kategori/";
  if(typeof document != "undefined"){
    pathname = document.location.pathname;
  }

  let term = decodeURI(pathname.split("/")[2].replace(/-/g, " "));
  let page = pathname.split("/")[3] ? pathname.split("/")[3] : 1;

  const [results, setResults] = useState([]);
  const [count, setCount] = useState([]);
  const fetchResults = (searchTerm) => {
    fetch(`https://api.etimolojiturkce.com/category/${encodeURI(searchTerm)}/${(page - 1)*20}`).then(res => res.json()).then((result) => {
      setResults(result.data);
      setCount(result.count);
      if(result.count == 0){
        navigate("/404");
      }
    });
  }

  useEffect(() => {
    fetchResults(term);
  }, [term, page]);

  return (
    <Layout>
      <h1><>{term} Kategorisi</> {page != 1 ? <span>(Sayfa {page})</span> : <></>}</h1>
      <p>Bu sayfada, sitemizdeki {term} kategorisi ile ilgili olan kelimelerin etimolojik incelemeleri listelenmiştir, ayrıntıları için kelimeye tıklayın.</p>

      {results && results.map((res, i) => {
        return(
          <div key={i}>
            <Link to={`/kelime/${res.kelime}`}><h3>{res.kelime}</h3></Link>
            <p dangerouslySetInnerHTML={{__html: res.koken}}></p>
          </div>
        )
      })}

      <div className="navigation">
        <Link to={page != 1 ? `/kategori/${pathname.split("/")[2]}${parseInt(page-1) != 1 ? `/${parseInt(page-1)}`: ""}` : "#"}>{page != 1 && <div className="navButton">🡐 Önceki</div>}</Link>
        <Link to={(count / 20) > page ? `/kategori/${pathname.split("/")[2]}/${parseInt(Number(page)+1)}` : "#"}>{(count / 20) > page && <div className="navButton">Sonraki 🡒</div>}</Link>
      </div>

      <SEO
        title={`${term} Kategorisi Kelime Kökenleri - Etimoloji`}
        description={`${term} kategorisi, kelimelerin kökeni ve etimolojik incelemesi. ${term} kelimesinin geçtiği en eski kaynak ve kelime etimolojisi, ${term} ne demek?`}
        meta={[
          {name: "canonical", content: `https://www.etimolojiturkce.com/kategori/${term.replace(/ /g, "-")}${page != 1 ? `/${page}`: ""}`}
        ]}
        />
    </Layout>
  )
}
